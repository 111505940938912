import React, { useRef } from "react";
import { useGLTF, useScroll } from "@react-three/drei";
import { useLayoutEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { gsap } from "gsap";
import { SingularSkillModel } from "./SingularSkillModel";
import { MultipleSkillModels } from "./MultipleSkillModels";

export const Neurons = ({}) => {
  const groupRef = useRef();
  const { nodes, materials } = useGLTF("/3dModels/mobileModels/Neurons.glb");

  const tl = useRef();
  const scroll = useScroll();
  const { camera } = useThree();

  useFrame(() => {
    // console.log(scroll.offset);
    tl.current.seek(scroll.offset);
  });

  useLayoutEffect(() => {
    tl.current = gsap.timeline();

    tl.current.from(
      groupRef.current.position,
      {
        duration: 0.001,
        x: -30
      },
      0.533
    );
    //move model down
    // tl.current.to(
    //   groupRef.current.position,
    //   {
    //     duration: 1.45,
    //     y: -60
    //   },
    //   0.54
    // );

    ////rotate to left
    tl.current.to(
      groupRef.current.rotation,
      {
        duration: 0.03,
        y: 2
      },
      0.533
    );
    //rotate to right
    tl.current.to(
      groupRef.current.rotation,
      {
        duration: 0.03,
        y: 0
      },
      0.59
    );
    //rotate to right
    tl.current.to(
      groupRef.current.rotation,
      {
        duration: 0.03,
        y: -2
      },
      0.68
    );

    //move out
    tl.current.to(
      groupRef.current.position,
      {
        duration: 0.001,
        x: 20,
        z: 20
      },
      0.795
    );
  }, []);

  return (
    <group
      ref={groupRef}
      dispose={null}
      position={[3.5, 0, 3]}
      rotation={[0, 1, 0]}
      scale={1.2}
    >
      <group
        position={[1.059, 0.425, 0.287]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.062}
      >
        <group scale={100}>
          <group rotation={[-Math.PI / 2, 0, 0]}>
            <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
              <group
                position={[-4.302, -20.583, 2.695]}
                rotation={[0.405, 0.413, 0.075]}
              >
                <group
                  position={[-0.63, 8.81, -0.11]}
                  rotation={[1.581, -0.072, 2.306]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0020.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0041.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0061.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0047.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0073.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0055.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0004.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0031.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0009.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0055.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0063.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0026.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[8.241, -0.514, -1.462]}
                  rotation={[0.697, -1.433, -2.427]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0028.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0008.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0049.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0037.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0002.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0015.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0003.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0002.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0046.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0037.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0021.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0032.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0075.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0050.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0027.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0032.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0043.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group position={[-20.488, -20.583, 0]}>
                <group
                  position={[-8.704, -0.514, -1.462]}
                  rotation={[0, 1.427, 0]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0029.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0005.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0019.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0040.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0037.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0034.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0068.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0017.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0033.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[-22.98, 15.105, 10.241]}
                  rotation={[1.77, 0.589, -1.03]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0031.geometry}
                    material={materials.phong2}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0001.geometry}
                    material={materials.phong2}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0004.geometry}
                    material={materials.phong2}
                    position={[-0.916, -6.266, 16.182]}
                  />
                </group>
                <group
                  position={[-0.63, 8.81, -0.11]}
                  rotation={[1.581, -0.072, 2.306]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0040.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0027.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0001.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0069.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0033.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0047.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0025.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0015.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0032.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0044.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0047.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0012.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[8.241, -0.514, -1.462]}
                  rotation={[0.697, -1.433, -2.427]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0070.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0026.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0016.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0002.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0014.geometry}
                    material={materials.phong2}
                  />
                </group>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0019.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0031.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0040.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0075.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0013.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0013.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0057.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0021.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0074.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group position={[-20.488, -3.878, 4.585]}>
                <group
                  position={[-8.704, -0.514, -1.462]}
                  rotation={[0, 1.427, 0]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0064.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0054.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0060.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0074.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0037.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0048.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0054.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0011.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0065.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0014.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0057.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[-0.957, -6.826, 2.25]}
                  rotation={[-2.27, 0.454, -0.418]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0055.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0048.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0042.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0017.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0056.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0020.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0059.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0035.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0048.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0063.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0018.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0055.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[-22.98, 15.105, 10.241]}
                  rotation={[1.77, 0.589, -1.03]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0048.geometry}
                    material={materials.phong2}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0062.geometry}
                    material={materials.phong2}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0059.geometry}
                    material={materials.phong2}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0010.geometry}
                    material={materials.phong2}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0043.geometry}
                    material={materials.phong2}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0034.geometry}
                    material={materials.phong2}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0014.geometry}
                    material={materials.phong1}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0046.geometry}
                    material={materials.m_oligo_inside}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0015.geometry}
                    material={materials.Myelin3}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0074.geometry}
                    material={materials.m_oligo_inside}
                    position={[-0.916, -6.266, 16.182]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0017.geometry}
                    material={materials.Myelin3}
                    position={[-0.916, -6.266, 16.182]}
                  />
                </group>
                <group
                  position={[-26.646, -1.699, 4.532]}
                  rotation={[-2.27, 0.454, -0.418]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0047.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0059.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0054.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0054.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0026.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0053.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0016.geometry}
                    material={materials.phong1}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0043.geometry}
                    material={materials.phong1}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0073.geometry}
                    material={materials.m_oligo_inside}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0027.geometry}
                    material={materials.Myelin3}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0054.geometry}
                    material={materials.m_oligo_inside}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0036.geometry}
                    material={materials.Myelin3}
                    position={[11.737, 6.502, 4.952]}
                  />
                </group>
                <group
                  position={[-12.244, -16.941, -0.966]}
                  rotation={[-2.678, 0.908, -1.96]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0054.geometry}
                    material={materials.phong2}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0068.geometry}
                    material={materials.phong2}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0021.geometry}
                    material={materials.phong2}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0026.geometry}
                    material={materials.phong2}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0010.geometry}
                    material={materials.phong2}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0065.geometry}
                    material={materials.phong2}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0040.geometry}
                    material={materials.phong1}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0048.geometry}
                    material={materials.phong1}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0066.geometry}
                    material={materials.m_oligo_inside}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0075.geometry}
                    material={materials.Myelin3}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0051.geometry}
                    material={materials.m_oligo_inside}
                    position={[11.692, 7.54, -3.313]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0046.geometry}
                    material={materials.Myelin3}
                    position={[11.692, 7.54, -3.313]}
                  />
                </group>
                <group
                  position={[-28.9, -12.72, 2.25]}
                  rotation={[-2.27, 0.454, -0.418]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0015.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0006.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0028.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0060.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0036.geometry}
                    material={materials.phong2}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0040.geometry}
                    material={materials.m_oligo_inside}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0056.geometry}
                    material={materials.Myelin3}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0020.geometry}
                    material={materials.m_oligo_inside}
                    position={[11.737, 6.502, 4.952]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0064.geometry}
                    material={materials.Myelin3}
                    position={[11.737, 6.502, 4.952]}
                  />
                </group>
                <group
                  position={[-0.63, 8.81, -0.11]}
                  rotation={[1.581, -0.072, 2.306]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0043.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0036.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0044.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0030.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0031.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0049.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0061.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0025.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0012.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0046.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0016.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0051.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[8.241, -0.514, -1.462]}
                  rotation={[0.697, -1.433, -2.427]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0033.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0035.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0048.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0048.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0072.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0060.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0064.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0020.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0040.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0060.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0048.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[-8.728, -8.521, -0.816]}
                  rotation={[-2.159, -0.127, -0.499]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0022.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0025.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0035.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0064.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0045.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0035.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0068.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0014.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0053.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0031.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0061.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0045.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[-1.691, -11.03, -4.194]}
                  rotation={[-2.466, 0.994, 0.281]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0008.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0021.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0024.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0056.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0051.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0062.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0070.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0033.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0004.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0001.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0037.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0044.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[-8.392, -5.456, -5.964]}
                  rotation={[0.755, 0.94, -2.307]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0072.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0012.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0051.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0051.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0024.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0030.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0071.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0010.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0008.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0017.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0027.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0041.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[-7.664, -6.826, 2.25]}
                  rotation={[-2.27, 0.454, -0.418]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0058.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0010.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0075.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0046.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0025.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0023.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0003.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0023.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0023.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0074.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0050.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0038.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[-6.327, -6.597, 8.323]}
                  rotation={[-2.052, 1.044, -0.605]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0051.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0071.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0038.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0004.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0074.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0005.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0029.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0024.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0045.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0031.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <group
                  position={[-3.019, -1.66, 5.3]}
                  rotation={[-0.602, 0.019, -0.012]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube1_phong2_0050.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube2_phong2_0067.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube3_phong2_0028.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube4_phong2_0041.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube5_phong2_0058.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.pCube6_phong2_0016.geometry}
                    material={materials.phong2}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron1_phong1_0009.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Main_Neuron2_phong1_0032.geometry}
                    material={materials.phong1}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_Oligo1_m_oligo_inside_0042.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte_Myelin3_0008.geometry}
                    material={materials.Myelin3}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Inside_oligo2_m_oligo_inside_0028.geometry}
                    material={materials.m_oligo_inside}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Oligodendrocyte2_Myelin3_0009.geometry}
                    material={materials.Myelin3}
                  />
                </group>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0023.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0069.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0020.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0018.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0068.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0032.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron3_phong1_0.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0060.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0043.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0008.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0020.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group
                position={[-8.704, -0.514, -1.462]}
                rotation={[0, 1.427, 0]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0042.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0042.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0041.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0036.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0067.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0006.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0057.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0005.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0039.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0062.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0034.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group
                position={[-0.957, -6.826, 2.25]}
                rotation={[-2.27, 0.454, -0.418]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0063.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0053.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0047.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0011.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0018.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0045.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0062.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0024.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0061.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0053.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0054.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group
                position={[-22.98, 15.105, 10.241]}
                rotation={[1.77, 0.589, -1.03]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0069.geometry}
                  material={materials.phong2}
                  position={[-0.916, -6.266, 16.182]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0043.geometry}
                  material={materials.phong2}
                  position={[-0.916, -6.266, 16.182]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0013.geometry}
                  material={materials.phong2}
                  position={[-0.916, -6.266, 16.182]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0021.geometry}
                  material={materials.phong2}
                  position={[-0.916, -6.266, 16.182]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0042.geometry}
                  material={materials.phong2}
                  position={[-0.916, -6.266, 16.182]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0033.geometry}
                  material={materials.phong2}
                  position={[-0.916, -6.266, 16.182]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0012.geometry}
                  material={materials.phong1}
                  position={[-0.916, -6.266, 16.182]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0061.geometry}
                  material={materials.m_oligo_inside}
                  position={[-0.916, -6.266, 16.182]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0033.geometry}
                  material={materials.Myelin3}
                  position={[-0.916, -6.266, 16.182]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0073.geometry}
                  material={materials.m_oligo_inside}
                  position={[-0.916, -6.266, 16.182]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0062.geometry}
                  material={materials.Myelin3}
                  position={[-0.916, -6.266, 16.182]}
                />
              </group>
              <group
                position={[-26.646, -1.699, 4.532]}
                rotation={[-2.27, 0.454, -0.418]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0067.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0049.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0015.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0006.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0030.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0042.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0022.geometry}
                  material={materials.phong1}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0051.geometry}
                  material={materials.phong1}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0055.geometry}
                  material={materials.m_oligo_inside}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0051.geometry}
                  material={materials.Myelin3}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0031.geometry}
                  material={materials.m_oligo_inside}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0060.geometry}
                  material={materials.Myelin3}
                  position={[11.737, 6.502, 4.952]}
                />
              </group>
              <group
                position={[-12.244, -16.941, -0.966]}
                rotation={[-2.678, 0.908, -1.96]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0066.geometry}
                  material={materials.phong2}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0056.geometry}
                  material={materials.phong2}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0017.geometry}
                  material={materials.phong2}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0001.geometry}
                  material={materials.phong2}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0021.geometry}
                  material={materials.phong2}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0056.geometry}
                  material={materials.phong2}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0032.geometry}
                  material={materials.phong1}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0007.geometry}
                  material={materials.phong1}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0027.geometry}
                  material={materials.m_oligo_inside}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0042.geometry}
                  material={materials.Myelin3}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0002.geometry}
                  material={materials.m_oligo_inside}
                  position={[11.692, 7.54, -3.313]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0001.geometry}
                  material={materials.Myelin3}
                  position={[11.692, 7.54, -3.313]}
                />
              </group>
              <group
                position={[-28.9, -12.72, 2.25]}
                rotation={[-2.27, 0.454, -0.418]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0060.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0061.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0058.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0003.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0003.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0070.geometry}
                  material={materials.phong2}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0036.geometry}
                  material={materials.phong1}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0045.geometry}
                  material={materials.phong1}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0002.geometry}
                  material={materials.m_oligo_inside}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0023.geometry}
                  material={materials.Myelin3}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0057.geometry}
                  material={materials.m_oligo_inside}
                  position={[11.737, 6.502, 4.952]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0032.geometry}
                  material={materials.Myelin3}
                  position={[11.737, 6.502, 4.952]}
                />
              </group>
              <group
                position={[-16.719, -19.772, 7.155]}
                rotation={[-1.625, -0.128, 0.418]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0057.geometry}
                  material={materials.phong2}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0064.geometry}
                  material={materials.phong2}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0032.geometry}
                  material={materials.phong2}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0009.geometry}
                  material={materials.phong2}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0006.geometry}
                  material={materials.phong2}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0069.geometry}
                  material={materials.phong2}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0026.geometry}
                  material={materials.phong1}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0016.geometry}
                  material={materials.phong1}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0067.geometry}
                  material={materials.m_oligo_inside}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0016.geometry}
                  material={materials.Myelin3}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0039.geometry}
                  material={materials.m_oligo_inside}
                  position={[1.977, -3.395, 3.595]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0024.geometry}
                  material={materials.Myelin3}
                  position={[1.977, -3.395, 3.595]}
                />
              </group>
              <group
                position={[-9.882, -18.213, 5.3]}
                rotation={[-0.602, 0.019, -0.012]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0056.geometry}
                  material={materials.phong2}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0070.geometry}
                  material={materials.phong2}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0025.geometry}
                  material={materials.phong2}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0022.geometry}
                  material={materials.phong2}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0016.geometry}
                  material={materials.phong2}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0064.geometry}
                  material={materials.phong2}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0017.geometry}
                  material={materials.phong1}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0012.geometry}
                  material={materials.phong1}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0026.geometry}
                  material={materials.m_oligo_inside}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0010.geometry}
                  material={materials.Myelin3}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0022.geometry}
                  material={materials.m_oligo_inside}
                  position={[2.855, 1.619, 4.194]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0015.geometry}
                  material={materials.Myelin3}
                  position={[2.855, 1.619, 4.194]}
                />
              </group>
              <group
                position={[-0.63, 8.81, -0.11]}
                rotation={[1.581, -0.072, 2.306]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0011.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0047.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0074.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0070.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0007.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0017.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0056.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0038.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0050.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0038.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0010.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0056.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group
                position={[8.241, -0.514, -1.462]}
                rotation={[0.697, -1.433, -2.427]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0009.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0055.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0050.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0055.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0041.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0005.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0052.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0007.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0066.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0052.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0066.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group
                position={[-8.728, -8.521, -0.816]}
                rotation={[-2.159, -0.127, -0.499]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0005.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0070.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0052.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0057.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0021.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0047.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0056.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0054.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0072.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0025.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0023.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group
                position={[-1.691, -11.03, -4.194]}
                rotation={[-2.466, 0.994, 0.281]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0004.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0004.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0045.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0064.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0007.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0066.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0037.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0036.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0048.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0013.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0040.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group
                position={[-8.392, -5.456, -5.964]}
                rotation={[0.755, 0.94, -2.307]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0003.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0014.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0002.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0035.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0029.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0028.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0031.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0058.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0018.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0058.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0004.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0021.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group
                position={[-7.664, -6.826, 2.25]}
                rotation={[-2.27, 0.454, -0.418]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0075.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0018.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0005.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0032.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0074.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0002.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0055.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0039.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0014.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0053.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0007.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0019.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group
                position={[-6.327, -6.597, 8.323]}
                rotation={[-2.052, 1.044, -0.605]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0074.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0029.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0008.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0027.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0061.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0011.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0065.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0030.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0044.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0045.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0034.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0047.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <group
                position={[-3.019, -1.66, 5.3]}
                rotation={[-0.602, 0.019, -0.012]}
              >
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube1_phong2_0073.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube2_phong2_0039.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube3_phong2_0012.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube4_phong2_0023.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube5_phong2_0052.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.pCube6_phong2_0050.geometry}
                  material={materials.phong2}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron1_phong1_0001.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Main_Neuron2_phong1_0059.geometry}
                  material={materials.phong1}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_Oligo1_m_oligo_inside_0028.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte_Myelin3_0020.geometry}
                  material={materials.Myelin3}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Inside_oligo2_m_oligo_inside_0068.geometry}
                  material={materials.m_oligo_inside}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.Oligodendrocyte2_Myelin3_0065.geometry}
                  material={materials.Myelin3}
                />
              </group>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.pCube1_phong2_0052.geometry}
                material={materials.phong2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.pCube2_phong2_0002.geometry}
                material={materials.phong2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.pCube3_phong2_0018.geometry}
                material={materials.phong2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.pCube4_phong2_0029.geometry}
                material={materials.phong2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.pCube5_phong2_0034.geometry}
                material={materials.phong2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.pCube6_phong2_0061.geometry}
                material={materials.phong2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Main_Neuron3_phong1_0001.geometry}
                material={materials.phong1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Inside_Oligo1_m_oligo_inside_0052.geometry}
                material={materials.m_oligo_inside}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Oligodendrocyte_Myelin3_0004.geometry}
                material={materials.Myelin3}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Inside_oligo2_m_oligo_inside_0009.geometry}
                material={materials.m_oligo_inside}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Oligodendrocyte2_Myelin3_0007.geometry}
                material={materials.Myelin3}
              />
            </group>
          </group>
        </group>
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere.geometry}
        material={materials["Material.001"]}
        scale={4.294}
      />
    </group>
  );
};

useGLTF.preload("/3dModels/mobileModels/Neurons.glb");
